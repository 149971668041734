/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFinancingOptions = /* GraphQL */ `
  query GetFinancingOptions($doc_id: Int!) {
    getFinancingOptions(doc_id: $doc_id)
  }
`;
export const saveFinancingOptions = /* GraphQL */ `
  query SaveFinancingOptions($options: String!) {
    saveFinancingOptions(options: $options)
  }
`;
export const createFinancingOptions = /* GraphQL */ `
  query CreateFinancingOptions(
    $user_id: Int!
    $doc_id: Int!
    $financing_option: String
    $is_default: String
  ) {
    createFinancingOptions(
      user_id: $user_id
      doc_id: $doc_id
      financing_option: $financing_option
      is_default: $is_default
    )
  }
`;
export const saveLineItmAsDefault = /* GraphQL */ `
  query SaveLineItmAsDefault(
    $force_update: Boolean
    $line_item_id: Int
    $client_id: Int!
    $team_id: Int!
    $name: String!
    $description: String
    $manufacturer_id: Int
    $sku: String
    $line_item_code: String
    $scope_type_id: Int
    $cost: Float
    $quantity: Float
    $adjusted_quantity: Float
    $unit: String
    $convert_to: String
    $ratio_from: Float
    $ratio_to: Float
    $convert_round: Boolean
    $algorithm: String
    $labor_cost: Float
    $material_cost: Float
    $profit: Float
    $overhead: Float
    $waste: Float
    $notes: String
    $sub_line_item: String
    $product: String
    $manufacturer: String
    $apply_op: Int
    $status: String
  ) {
    saveLineItmAsDefault(
      force_update: $force_update
      line_item_id: $line_item_id
      client_id: $client_id
      team_id: $team_id
      name: $name
      description: $description
      manufacturer_id: $manufacturer_id
      sku: $sku
      line_item_code: $line_item_code
      scope_type_id: $scope_type_id
      cost: $cost
      quantity: $quantity
      adjusted_quantity: $adjusted_quantity
      unit: $unit
      convert_to: $convert_to
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      convert_round: $convert_round
      algorithm: $algorithm
      labor_cost: $labor_cost
      material_cost: $material_cost
      profit: $profit
      overhead: $overhead
      waste: $waste
      notes: $notes
      sub_line_item: $sub_line_item
      product: $product
      manufacturer: $manufacturer
      apply_op: $apply_op
      status: $status
    )
  }
`;
export const updateFinancingOptions = /* GraphQL */ `
  query UpdateFinancingOptions(
    $user_id: Int!
    $doc_id: Int!
    $financing_option: String
    $is_default: String
  ) {
    updateFinancingOptions(
      user_id: $user_id
      doc_id: $doc_id
      financing_option: $financing_option
      is_default: $is_default
    )
  }
`;
export const updateQuickGenerate = /* GraphQL */ `
  query UpdateQuickGenerate($client_id: Int!, $one_click: Int) {
    updateQuickGenerate(client_id: $client_id, one_click: $one_click)
  }
`;
export const updateTempEditRestriction = /* GraphQL */ `
  query UpdateTempEditRestriction($client_id: Int!, $pd_team_edit: Int) {
    updateTempEditRestriction(
      client_id: $client_id
      pd_team_edit: $pd_team_edit
    )
  }
`;
export const updateClientEstContact = /* GraphQL */ `
  query UpdateClientEstContact(
    $client_id: Int!
    $prodocs_estimate_contact_email: String
    $prodocs_estimate_contact_first_name: String
    $prodocs_estimate_contact_last_name: String
    $prodocs_estimate_contact_phone: String
  ) {
    updateClientEstContact(
      client_id: $client_id
      prodocs_estimate_contact_email: $prodocs_estimate_contact_email
      prodocs_estimate_contact_first_name: $prodocs_estimate_contact_first_name
      prodocs_estimate_contact_last_name: $prodocs_estimate_contact_last_name
      prodocs_estimate_contact_phone: $prodocs_estimate_contact_phone
    )
  }
`;
export const updateClientWrkContact = /* GraphQL */ `
  query UpdateClientWrkContact(
    $client_id: Int!
    $prodocs_work_order_contact_email: String
    $prodocs_work_order_contact_first_name: String
    $prodocs_work_order_contact_last_name: String
    $prodocs_work_order_contact_phone: String
  ) {
    updateClientWrkContact(
      client_id: $client_id
      prodocs_work_order_contact_email: $prodocs_work_order_contact_email
      prodocs_work_order_contact_first_name: $prodocs_work_order_contact_first_name
      prodocs_work_order_contact_last_name: $prodocs_work_order_contact_last_name
      prodocs_work_order_contact_phone: $prodocs_work_order_contact_phone
    )
  }
`;
export const updateClientBranding = /* GraphQL */ `
  query UpdateClientBranding($client_id: Int!, $color: String, $logo: String) {
    updateClientBranding(client_id: $client_id, color: $color, logo: $logo)
  }
`;
export const getAuthToken = /* GraphQL */ `
  query GetAuthToken($client_id: Int, $token: String) {
    getAuthToken(client_id: $client_id, token: $token)
  }
`;
export const getOderScopeLoc = /* GraphQL */ `
  query GetOderScopeLoc($order_id: Int) {
    getOderScopeLoc(order_id: $order_id)
  }
`;
export const createAuthStartsupply = /* GraphQL */ `
  query CreateAuthStartsupply($doc_id: Int, $data: String) {
    createAuthStartsupply(doc_id: $doc_id, data: $data)
  }
`;
export const searchAllLineItems = /* GraphQL */ `
  query SearchAllLineItems(
    $client_id: Int
    $team_id: Int
    $search: String
    $man_ids: String
  ) {
    searchAllLineItems(
      client_id: $client_id
      team_id: $team_id
      search: $search
      man_ids: $man_ids
    )
  }
`;
export const getSearchedContactsByClientID = /* GraphQL */ `
  query GetSearchedContactsByClientID(
    $client_id: Int!
    $type: String!
    $search: String!
    $limit: Int!
    $selected_contact: Int
  ) {
    getSearchedContactsByClientID(
      client_id: $client_id
      type: $type
      search: $search
      limit: $limit
      selected_contact: $selected_contact
    )
  }
`;
export const countContactsByClientID = /* GraphQL */ `
  query CountContactsByClientID($client_id: Int!, $type: String!) {
    countContactsByClientID(client_id: $client_id, type: $type)
  }
`;
export const updateStructureCount = /* GraphQL */ `
  query UpdateStructureCount($doc_id: Int, $structure_count: Int) {
    updateStructureCount(doc_id: $doc_id, structure_count: $structure_count)
  }
`;
export const createProductAndManufacturerES = /* GraphQL */ `
  query CreateProductAndManufacturerES($data: String) {
    createProductAndManufacturerES(data: $data)
  }
`;
export const createAllUserTempsOnElasticsearch = /* GraphQL */ `
  query CreateAllUserTempsOnElasticsearch(
    $user_id: Int
    $team_id: Int
    $doc_type_id: Int
    $start_date: String
    $end_date: String
    $email: String
  ) {
    createAllUserTempsOnElasticsearch(
      user_id: $user_id
      team_id: $team_id
      doc_type_id: $doc_type_id
      start_date: $start_date
      end_date: $end_date
      email: $email
    )
  }
`;
export const createAllUserDocsOnElasticsearch = /* GraphQL */ `
  query CreateAllUserDocsOnElasticsearch(
    $user_id: Int
    $team_id: Int
    $doc_type_id: Int
    $start_date: String
    $end_date: String
    $email: String
  ) {
    createAllUserDocsOnElasticsearch(
      user_id: $user_id
      team_id: $team_id
      doc_type_id: $doc_type_id
      start_date: $start_date
      end_date: $end_date
      email: $email
    )
  }
`;
export const getProductAndManufacturerId = /* GraphQL */ `
  query GetProductAndManufacturerId($data: String) {
    getProductAndManufacturerId(data: $data)
  }
`;
export const checkClientTempTokenFromRedis = /* GraphQL */ `
  query CheckClientTempTokenFromRedis($client_id: Int, $logged_in_id: Int) {
    checkClientTempTokenFromRedis(
      client_id: $client_id
      logged_in_id: $logged_in_id
    )
  }
`;
export const getUserDataForProxyLogin = /* GraphQL */ `
  query GetUserDataForProxyLogin($id: Int, $email: String) {
    getUserDataForProxyLogin(id: $id, email: $email)
  }
`;
export const getEsDownDocs = /* GraphQL */ `
  query GetEsDownDocs($team_id: Int) {
    getEsDownDocs(team_id: $team_id)
  }
`;
export const updateDocModDate = /* GraphQL */ `
  query UpdateDocModDate(
    $doc_id: Int
    $date_updated: String
    $updated_by: Int
    $pdf: String
    $es_down_flag: String
  ) {
    updateDocModDate(
      doc_id: $doc_id
      date_updated: $date_updated
      updated_by: $updated_by
      pdf: $pdf
      es_down_flag: $es_down_flag
    )
  }
`;
export const setEsDownFlagDoc = /* GraphQL */ `
  query SetEsDownFlagDoc($doc_id: Int!, $flag: String!) {
    setEsDownFlagDoc(doc_id: $doc_id, flag: $flag)
  }
`;
export const setEsDownFlagTemp = /* GraphQL */ `
  query SetEsDownFlagTemp($template_id: Int!, $flag: String!) {
    setEsDownFlagTemp(template_id: $template_id, flag: $flag)
  }
`;
export const updateDocNotes = /* GraphQL */ `
  query UpdateDocNotes($doc_id: Int, $notes: String) {
    updateDocNotes(doc_id: $doc_id, notes: $notes)
  }
`;
export const updateDocFooter = /* GraphQL */ `
  query UpdateDocFooter($doc_id: Int, $footer: String) {
    updateDocFooter(doc_id: $doc_id, footer: $footer)
  }
`;
export const adminLogin = /* GraphQL */ `
  query AdminLogin($user_name: String, $password: String) {
    adminLogin(user_name: $user_name, password: $password)
  }
`;
export const getMxDocID = /* GraphQL */ `
  query GetMxDocID($id: Int) {
    getMxDocID(id: $id)
  }
`;
export const getContactById = /* GraphQL */ `
  query GetContactById($contact_id: Int) {
    getContactById(contact_id: $contact_id)
  }
`;
export const getReportsRedis = /* GraphQL */ `
  query GetReportsRedis($id: Int) {
    getReportsRedis(id: $id)
  }
`;
export const createOnRedis = /* GraphQL */ `
  query CreateOnRedis($index: String, $id: Int, $data: String) {
    createOnRedis(index: $index, id: $id, data: $data)
  }
`;
export const getFromRedis = /* GraphQL */ `
  query GetFromRedis($index: String, $id: Int) {
    getFromRedis(index: $index, id: $id)
  }
`;
export const getDefaultTemplatesRedis = /* GraphQL */ `
  query GetDefaultTemplatesRedis($id: Int) {
    getDefaultTemplatesRedis(id: $id)
  }
`;
export const getManufacturerLineItemRedis = /* GraphQL */ `
  query GetManufacturerLineItemRedis($id: Int) {
    getManufacturerLineItemRedis(id: $id)
  }
`;
export const getDefaultManufacturersRedis = /* GraphQL */ `
  query GetDefaultManufacturersRedis($id: Int) {
    getDefaultManufacturersRedis(id: $id)
  }
`;
export const getCategoriesWithLineitemsRedis = /* GraphQL */ `
  query GetCategoriesWithLineitemsRedis($id: Int) {
    getCategoriesWithLineitemsRedis(id: $id)
  }
`;
export const updateDocStructureSortOrder = /* GraphQL */ `
  query UpdateDocStructureSortOrder($doc_structure_id: Int, $sort_order: Int) {
    updateDocStructureSortOrder(
      doc_structure_id: $doc_structure_id
      sort_order: $sort_order
    )
  }
`;
export const updateDocLineItemSortOrder = /* GraphQL */ `
  query UpdateDocLineItemSortOrder($doc_line_item_id: Int, $sort_order: Int) {
    updateDocLineItemSortOrder(
      doc_line_item_id: $doc_line_item_id
      sort_order: $sort_order
    )
  }
`;
export const updateDocCatSortOrder = /* GraphQL */ `
  query UpdateDocCatSortOrder($doc_category_id: Int, $sort_order: Int) {
    updateDocCatSortOrder(
      doc_category_id: $doc_category_id
      sort_order: $sort_order
    )
  }
`;
export const addDocSubLineItem = /* GraphQL */ `
  query AddDocSubLineItem($doc_line_item_id: Int, $sub_line_item: String) {
    addDocSubLineItem(
      doc_line_item_id: $doc_line_item_id
      sub_line_item: $sub_line_item
    )
  }
`;
export const elasticSearchUpdateDocOrTemp = /* GraphQL */ `
  query ElasticSearchUpdateDocOrTemp(
    $id: Int
    $index: String
    $template: String
  ) {
    elasticSearchUpdateDocOrTemp(id: $id, index: $index, template: $template)
  }
`;
export const elasticSearchCreateDocOrTemp = /* GraphQL */ `
  query ElasticSearchCreateDocOrTemp(
    $id: Int
    $index: String
    $template: String
  ) {
    elasticSearchCreateDocOrTemp(id: $id, index: $index, template: $template)
  }
`;
export const elasticSearchDeleteDocOrTemp = /* GraphQL */ `
  query ElasticSearchDeleteDocOrTemp($id: Int, $index: String) {
    elasticSearchDeleteDocOrTemp(id: $id, index: $index)
  }
`;
export const elasticSearchFetchDoc = /* GraphQL */ `
  query ElasticSearchFetchDoc($doc_id: Int) {
    elasticSearchFetchDoc(doc_id: $doc_id)
  }
`;
export const elasticSearchFetchTemp = /* GraphQL */ `
  query ElasticSearchFetchTemp($template_id: Int) {
    elasticSearchFetchTemp(template_id: $template_id)
  }
`;
export const downloadTemplateData = /* GraphQL */ `
  query DownloadTemplateData($template_id: Int) {
    downloadTemplateData(template_id: $template_id)
  }
`;
export const generatePdf = /* GraphQL */ `
  query GeneratePdf($docData: String!) {
    generatePdf(docData: $docData)
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail(
    $subject: String!
    $to_email: String!
    $url_download: String!
    $email_msg: String!
    $client_email: String!
    $client_id: Int!
  ) {
    sendEmail(
      subject: $subject
      to_email: $to_email
      url_download: $url_download
      email_msg: $email_msg
      client_email: $client_email
      client_id: $client_id
    )
  }
`;
export const getDocOrderIds = /* GraphQL */ `
  query GetDocOrderIds($doc_id: Int) {
    getDocOrderIds(doc_id: $doc_id)
  }
`;
export const addDocOrders = /* GraphQL */ `
  query AddDocOrders(
    $doc_id: Int!
    $doc_type_id: Int!
    $order_id: Int
    $scope_type_id: Int
    $scope_ids: String!
    $data: String!
    $token: String!
    $action: String!
  ) {
    addDocOrders(
      doc_id: $doc_id
      doc_type_id: $doc_type_id
      order_id: $order_id
      scope_type_id: $scope_type_id
      scope_ids: $scope_ids
      data: $data
      token: $token
      action: $action
    )
  }
`;
export const removeDocOrderId = /* GraphQL */ `
  query RemoveDocOrderId($order_id: Int, $doc_id: Int) {
    removeDocOrderId(order_id: $order_id, doc_id: $doc_id)
  }
`;
export const updateContactAddress = /* GraphQL */ `
  query UpdateContactAddress(
    $address_id: Int
    $name: String
    $company: String
    $address: String
    $address_2: String
    $city: String
    $postcode: String
    $country_id: Int
    $zone_id: Int
  ) {
    updateContactAddress(
      address_id: $address_id
      name: $name
      company: $company
      address: $address
      address_2: $address_2
      city: $city
      postcode: $postcode
      country_id: $country_id
      zone_id: $zone_id
    )
  }
`;
export const updateContactInfox = /* GraphQL */ `
  query UpdateContactInfox(
    $contact_id: Int
    $company: String
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
  ) {
    updateContactInfox(
      contact_id: $contact_id
      company: $company
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
    )
  }
`;
export const deleteContact = /* GraphQL */ `
  query DeleteContact($contact_id: Int) {
    deleteContact(contact_id: $contact_id)
  }
`;
export const deleteAddress = /* GraphQL */ `
  query DeleteAddress($address_id: Int) {
    deleteAddress(address_id: $address_id)
  }
`;
export const createContactInfo = /* GraphQL */ `
  query CreateContactInfo(
    $customer_id: Int
    $type: String
    $company: String
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
    $address_id: Int
    $template_id: Int
    $used_default: Int
  ) {
    createContactInfo(
      customer_id: $customer_id
      type: $type
      company: $company
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
      address_id: $address_id
      template_id: $template_id
      used_default: $used_default
    )
  }
`;
export const createContactAddress = /* GraphQL */ `
  query CreateContactAddress(
    $name: String
    $company: String
    $address: String
    $address_2: String
    $city: String
    $postcode: String
    $country_id: Int
    $zone_id: Int
    $locked: Int
    $status: Int
  ) {
    createContactAddress(
      name: $name
      company: $company
      address: $address
      address_2: $address_2
      city: $city
      postcode: $postcode
      country_id: $country_id
      zone_id: $zone_id
      locked: $locked
      status: $status
    )
  }
`;
export const updateDocContactId = /* GraphQL */ `
  query UpdateDocContactId($doc_id: Int, $contact_id: Int) {
    updateDocContactId(doc_id: $doc_id, contact_id: $contact_id)
  }
`;
export const updateTempDescription = /* GraphQL */ `
  query UpdateTempDescription($template_id: Int, $description: String) {
    updateTempDescription(template_id: $template_id, description: $description)
  }
`;
export const updateDocOptions = /* GraphQL */ `
  query UpdateDocOptions($doc_id: Int, $options: String) {
    updateDocOptions(doc_id: $doc_id, options: $options)
  }
`;
export const applyWorkOrderTemplate = /* GraphQL */ `
  query ApplyWorkOrderTemplate($temp_id: Int, $doc_id: Int, $doc_type_id: Int) {
    applyWorkOrderTemplate(
      temp_id: $temp_id
      doc_id: $doc_id
      doc_type_id: $doc_type_id
    )
  }
`;
export const assignDefaultTemplates = /* GraphQL */ `
  query AssignDefaultTemplates(
    $user_id: Int
    $scope_type_id: Int
    $doc_type_id: Int
    $template_id: Int
  ) {
    assignDefaultTemplates(
      user_id: $user_id
      scope_type_id: $scope_type_id
      doc_type_id: $doc_type_id
      template_id: $template_id
    )
  }
`;
export const getAssignDefaultTemplates = /* GraphQL */ `
  query GetAssignDefaultTemplates($user_id: Int) {
    getAssignDefaultTemplates(user_id: $user_id)
  }
`;
export const updateLineItem = /* GraphQL */ `
  query UpdateLineItem(
    $name: String
    $doc_line_item_id: Int
    $adjusted_quantity: Float
    $cost: Float
    $quantity: Float
    $unit: String
    $waste: Float
    $labor_cost: Float
    $material_cost: Float
    $overhead: Float
    $profit: Float
    $apply_op: Int
    $remove_prices: Int
  ) {
    updateLineItem(
      name: $name
      doc_line_item_id: $doc_line_item_id
      adjusted_quantity: $adjusted_quantity
      cost: $cost
      quantity: $quantity
      unit: $unit
      waste: $waste
      labor_cost: $labor_cost
      material_cost: $material_cost
      overhead: $overhead
      profit: $profit
      apply_op: $apply_op
      remove_prices: $remove_prices
    )
  }
`;
export const getDocumentByUserIDExport = /* GraphQL */ `
  query GetDocumentByUserIDExport(
    $user_id: Int!
    $team_id: Int!
    $limit: Int!
  ) {
    getDocumentByUserIDExport(
      user_id: $user_id
      team_id: $team_id
      limit: $limit
    )
  }
`;
export const updateMarkup = /* GraphQL */ `
  query UpdateMarkup(
    $doc_markup_id: Int
    $type: String
    $value: Float
    $for: String
    $percent: Float
  ) {
    updateMarkup(
      doc_markup_id: $doc_markup_id
      type: $type
      value: $value
      for: $for
      percent: $percent
    )
  }
`;
export const updateLineItemWorkOrder = /* GraphQL */ `
  query UpdateLineItemWorkOrder(
    $name: String
    $doc_line_item_id: Int
    $quantity: Float
    $unit: String
    $convert_to: String
    $algorithm: String
    $ratio_from: Float
    $ratio_to: Float
    $convert_round: Int
    $manufacturer: String
    $color: String
    $cost: Float
    $waste: Float
    $adjusted_quantity: Float
    $apply_op: Int
    $remove_prices: Int
  ) {
    updateLineItemWorkOrder(
      name: $name
      doc_line_item_id: $doc_line_item_id
      quantity: $quantity
      unit: $unit
      convert_to: $convert_to
      algorithm: $algorithm
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      convert_round: $convert_round
      manufacturer: $manufacturer
      color: $color
      cost: $cost
      waste: $waste
      adjusted_quantity: $adjusted_quantity
      apply_op: $apply_op
      remove_prices: $remove_prices
    )
  }
`;
export const createDocWithTemplate = /* GraphQL */ `
  query CreateDocWithTemplate($temp_id: Int, $doc_id: Int, $doc_data: String) {
    createDocWithTemplate(
      temp_id: $temp_id
      doc_id: $doc_id
      doc_data: $doc_data
    )
  }
`;
export const createDocCatWithLineItems = /* GraphQL */ `
  query CreateDocCatWithLineItems(
    $doc_id: Int
    $doc_structure_id: Int
    $strObject: String
    $lineObject: String
  ) {
    createDocCatWithLineItems(
      doc_id: $doc_id
      doc_structure_id: $doc_structure_id
      strObject: $strObject
      lineObject: $lineObject
    )
  }
`;
export const createDocCategory = /* GraphQL */ `
  query CreateDocCategory(
    $doc_id: Int
    $doc_structure_id: Int
    $category_id: Int
    $name: String
    $notes: String
    $sort_order: Int
  ) {
    createDocCategory(
      doc_id: $doc_id
      doc_structure_id: $doc_structure_id
      category_id: $category_id
      name: $name
      notes: $notes
      sort_order: $sort_order
    )
  }
`;
export const createDocument = /* GraphQL */ `
  query CreateDocument(
    $user_id: Int
    $team_id: Int
    $ref_id: String
    $doc_type_id: Int
    $template_id: Int
    $name: String
    $claim_number: String
    $contact_id: Int
    $owner_contact_id: Int
    $date_created: String
    $date_updated: String
    $location: String
  ) {
    createDocument(
      user_id: $user_id
      team_id: $team_id
      ref_id: $ref_id
      doc_type_id: $doc_type_id
      template_id: $template_id
      name: $name
      claim_number: $claim_number
      contact_id: $contact_id
      owner_contact_id: $owner_contact_id
      date_created: $date_created
      date_updated: $date_updated
      location: $location
    )
  }
`;
export const deleteDocumentWorkOrder = /* GraphQL */ `
  query DeleteDocumentWorkOrder($doc_id: Int) {
    deleteDocumentWorkOrder(doc_id: $doc_id)
  }
`;
export const updateDocStructName = /* GraphQL */ `
  query UpdateDocStructName($name: String, $doc_structure_id: Int) {
    updateDocStructName(name: $name, doc_structure_id: $doc_structure_id)
  }
`;
export const updateDocCategoryName = /* GraphQL */ `
  query UpdateDocCategoryName($name: String, $doc_category_id: Int) {
    updateDocCategoryName(name: $name, doc_category_id: $doc_category_id)
  }
`;
export const deleteLineItem = /* GraphQL */ `
  query DeleteLineItem($doc_line_item_id: Int) {
    deleteLineItem(doc_line_item_id: $doc_line_item_id)
  }
`;
export const deleteStructure = /* GraphQL */ `
  query DeleteStructure($doc_structure_id: Int) {
    deleteStructure(doc_structure_id: $doc_structure_id)
  }
`;
export const deleteDocumentCategory = /* GraphQL */ `
  query DeleteDocumentCategory($doc_category_id: Int) {
    deleteDocumentCategory(doc_category_id: $doc_category_id)
  }
`;
export const getDocumentWorkOrderData = /* GraphQL */ `
  query GetDocumentWorkOrderData($doc_id: Int) {
    getDocumentWorkOrderData(doc_id: $doc_id)
  }
`;
export const getDocumentEstimateData = /* GraphQL */ `
  query GetDocumentEstimateData($doc_id: Int) {
    getDocumentEstimateData(doc_id: $doc_id)
  }
`;
export const getAlgorithm = /* GraphQL */ `
  query GetAlgorithm($scopeType: Int) {
    getAlgorithm(scopeType: $scopeType)
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($client_id: Int) {
    getClient(client_id: $client_id) {
      client_id
      user_role_id
      username
      pd_team_edit
      first_name
      last_name
      account_balance
      transactional_account_balance
      token
      team_id
      admin_id
      partner_id
      __typename
    }
  }
`;
export const getWOTemplates = /* GraphQL */ `
  query GetWOTemplates($user_id: Int) {
    getWOTemplates(user_id: $user_id)
  }
`;
export const getEstimateTemplates = /* GraphQL */ `
  query GetEstimateTemplates($user_id: Int) {
    getEstimateTemplates(user_id: $user_id)
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($userName: String!, $accessPass: String!) {
    getToken(userName: $userName, accessPass: $accessPass)
  }
`;
export const getTemplatesByClientID = /* GraphQL */ `
  query GetTemplatesByClientID($user_id: ID!, $doc_type_id: Int!) {
    getTemplatesByClientID(user_id: $user_id, doc_type_id: $doc_type_id) {
      template_id
      doc_type_id
      user_id
      team_id
      shared
      name
      data
      date_created
      date_updated
      update_user_id
      description
      __typename
    }
  }
`;
export const getTemplatesByClientIDx = /* GraphQL */ `
  query GetTemplatesByClientIDx(
    $user_id: Int
    $doc_type_id: Int
    $limit: Int
    $offset: Int
  ) {
    getTemplatesByClientIDx(
      user_id: $user_id
      doc_type_id: $doc_type_id
      limit: $limit
      offset: $offset
    )
  }
`;
export const getTemplatesByTeamID = /* GraphQL */ `
  query GetTemplatesByTeamID(
    $team_id: Int
    $doc_type_id: Int
    $limit: Int
    $offset: Int
    $search: String
  ) {
    getTemplatesByTeamID(
      team_id: $team_id
      doc_type_id: $doc_type_id
      limit: $limit
      offset: $offset
      search: $search
    )
  }
`;
export const getTemplatesByTeamIDElasticsearch = /* GraphQL */ `
  query GetTemplatesByTeamIDElasticsearch(
    $team_id: Int
    $doc_type_id: Int
    $limit: Int
    $offset: Int
    $search: String
  ) {
    getTemplatesByTeamIDElasticsearch(
      team_id: $team_id
      doc_type_id: $doc_type_id
      limit: $limit
      offset: $offset
      search: $search
    )
  }
`;
export const getDocStructure = /* GraphQL */ `
  query GetDocStructure($doc_id: Int!) {
    getDocStructure(doc_id: $doc_id)
  }
`;
export const getDocCatagories = /* GraphQL */ `
  query GetDocCatagories($doc_id: Int!) {
    getDocCatagories(doc_id: $doc_id)
  }
`;
export const getDocLineItems = /* GraphQL */ `
  query GetDocLineItems($doc_id: Int!) {
    getDocLineItems(doc_id: $doc_id)
  }
`;
export const getDefaultTemplates = /* GraphQL */ `
  query GetDefaultTemplates {
    getDefaultTemplates {
      template_id
      doc_type_id
      user_id
      team_id
      shared
      name
      data
      date_created
      date_updated
      update_user_id
      description
      __typename
    }
  }
`;
export const getUnits = /* GraphQL */ `
  query GetUnits {
    getUnits {
      unit_id
      name
      label
      round
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($template_id: Int!) {
    getTemplate(template_id: $template_id) {
      template_id
      doc_type_id
      user_id
      team_id
      shared
      name
      data
      date_created
      date_updated
      update_user_id
      description
      __typename
    }
  }
`;
export const removeTemplate = /* GraphQL */ `
  query RemoveTemplate($template_id: ID!) {
    removeTemplate(template_id: $template_id)
  }
`;
export const shareTemplate = /* GraphQL */ `
  query ShareTemplate($template_id: ID!, $shared: Int!) {
    shareTemplate(template_id: $template_id, shared: $shared)
  }
`;
export const shareTemplateElasticsearch = /* GraphQL */ `
  query ShareTemplateElasticsearch($template_id: ID!, $shared: Int!) {
    shareTemplateElasticsearch(template_id: $template_id, shared: $shared)
  }
`;
export const saveTemplateJson = /* GraphQL */ `
  query SaveTemplateJson(
    $template_id: Int!
    $data: String!
    $date_updated: String
    $update_user_id: Int
    $es_down_flag: String
  ) {
    saveTemplateJson(
      template_id: $template_id
      data: $data
      date_updated: $date_updated
      update_user_id: $update_user_id
      es_down_flag: $es_down_flag
    )
  }
`;
export const saveEstimateDoc = /* GraphQL */ `
  query SaveEstimateDoc($doc_id: Int!, $data: String!) {
    saveEstimateDoc(doc_id: $doc_id, data: $data)
  }
`;
export const saveWorkOrderDoc = /* GraphQL */ `
  query SaveWorkOrderDoc($doc_id: Int!, $data: String!) {
    saveWorkOrderDoc(doc_id: $doc_id, data: $data)
  }
`;
export const AddMarkupOrTax = /* GraphQL */ `
  query AddMarkupOrTax(
    $user_id: Int!
    $team_id: Int!
    $type: String!
    $name: String
    $value: Float
    $for: String
    $percent: Float
    $default: Int
    $status: Int
  ) {
    AddMarkupOrTax(
      user_id: $user_id
      team_id: $team_id
      type: $type
      name: $name
      value: $value
      for: $for
      percent: $percent
      default: $default
      status: $status
    )
  }
`;
export const deleteMarkupOrTax = /* GraphQL */ `
  query DeleteMarkupOrTax($markup_id: Int!) {
    deleteMarkupOrTax(markup_id: $markup_id)
  }
`;
export const deleteDocMarkup = /* GraphQL */ `
  query DeleteDocMarkup($doc_markup_id: Int!) {
    deleteDocMarkup(doc_markup_id: $doc_markup_id)
  }
`;
export const saveDocMarkup = /* GraphQL */ `
  query SaveDocMarkup(
    $doc_id: Int!
    $markup_id: Int!
    $type: String!
    $name: String
    $value: Float
    $sort_order: Int
    $for: String
    $percent: Float
  ) {
    saveDocMarkup(
      doc_id: $doc_id
      markup_id: $markup_id
      type: $type
      name: $name
      value: $value
      sort_order: $sort_order
      for: $for
      percent: $percent
    )
  }
`;
export const getDocId = /* GraphQL */ `
  query GetDocId($template_id: Int!) {
    getDocId(template_id: $template_id)
  }
`;
export const saveTemplate = /* GraphQL */ `
  query SaveTemplate(
    $doc_type_id: Int!
    $user_id: Int!
    $team_id: Int!
    $data: String!
    $name: String!
    $update_user_id: Int!
  ) {
    saveTemplate(
      doc_type_id: $doc_type_id
      user_id: $user_id
      team_id: $team_id
      data: $data
      name: $name
      update_user_id: $update_user_id
    )
  }
`;
export const saveTemplateAs = /* GraphQL */ `
  query SaveTemplateAs(
    $doc_type_id: Int!
    $user_id: Int!
    $team_id: Int!
    $data: String!
    $name: String!
    $update_user_id: Int!
    $description: String
    $template_id: Int
  ) {
    saveTemplateAs(
      doc_type_id: $doc_type_id
      user_id: $user_id
      team_id: $team_id
      data: $data
      name: $name
      update_user_id: $update_user_id
      description: $description
      template_id: $template_id
    )
  }
`;
export const getCategoryByUserID = /* GraphQL */ `
  query GetCategoryByUserID($user_id: Int!, $team_id: Int!) {
    getCategoryByUserID(user_id: $user_id, team_id: $team_id) {
      category_id
      user_id
      team_id
      scope_type_id
      name
      status
      default_data
      __typename
    }
  }
`;
export const saveCategory = /* GraphQL */ `
  query SaveCategory(
    $user_id: Int!
    $team_id: Int!
    $scope_type_id: Int!
    $name: String!
    $status: Int!
    $default_data: String!
  ) {
    saveCategory(
      user_id: $user_id
      team_id: $team_id
      scope_type_id: $scope_type_id
      name: $name
      status: $status
      default_data: $default_data
    )
  }
`;
export const saveDocOrder = /* GraphQL */ `
  query SaveDocOrder(
    $doc_id: Int!
    $order_id: Int!
    $scope_type_id: Int!
    $name: String!
    $address: String!
  ) {
    saveDocOrder(
      doc_id: $doc_id
      order_id: $order_id
      scope_type_id: $scope_type_id
      name: $name
      address: $address
    )
  }
`;
export const removeCategory = /* GraphQL */ `
  query RemoveCategory($category_id: ID!) {
    removeCategory(category_id: $category_id)
  }
`;
export const removeContact = /* GraphQL */ `
  query RemoveContact($contact_id: ID!) {
    removeContact(contact_id: $contact_id)
  }
`;
export const getDefaultCategories = /* GraphQL */ `
  query GetDefaultCategories {
    getDefaultCategories {
      category_id
      user_id
      team_id
      scope_type_id
      name
      status
      default_data
      __typename
    }
  }
`;
export const getDefaultManufacturer = /* GraphQL */ `
  query GetDefaultManufacturer {
    getDefaultManufacturer {
      manufacturer_id
      name
      __typename
    }
  }
`;
export const getUsersManufacturers = /* GraphQL */ `
  query GetUsersManufacturers($team_id: Int) {
    getUsersManufacturers(team_id: $team_id)
  }
`;
export const removeUsersManufacturers = /* GraphQL */ `
  query RemoveUsersManufacturers($manufacturer_id: Int) {
    removeUsersManufacturers(manufacturer_id: $manufacturer_id)
  }
`;
export const saveUsersManufacturers = /* GraphQL */ `
  query SaveUsersManufacturers($user_id: Int, $team_id: Int, $name: String) {
    saveUsersManufacturers(user_id: $user_id, team_id: $team_id, name: $name)
  }
`;
export const getLineItemByMan = /* GraphQL */ `
  query GetLineItemByMan($manufacturer_id: Int) {
    getLineItemByMan(manufacturer_id: $manufacturer_id) {
      line_item_id
      manufacturer_id
      sku
      line_item_code
      user_id
      team_id
      doc_type_id
      scope_type_id
      name
      description
      cost
      quantity
      adjusted_quantity
      unit
      convert_to
      ratio_from
      ratio_to
      convert_round
      algorithm
      labor_cost
      material_cost
      profit
      overhead
      waste
      notes
      sub_line_item
      product
      manufacturer
      apply_op
      status
      sort_order
      doc_line_item_id
      doc_id
      doc_structure_id
      doc_category_id
      remove_prices
      __typename
    }
  }
`;
export const getUserByToken = /* GraphQL */ `
  query GetUserByToken($token: String) {
    getUserByToken(token: $token) {
      client_id
      user_role_id
      username
      pd_team_edit
      first_name
      last_name
      account_balance
      transactional_account_balance
      token
      team_id
      admin_id
      partner_id
      __typename
    }
  }
`;
export const getClientByID = /* GraphQL */ `
  query GetClientByID($client_id: Int) {
    getClientByID(client_id: $client_id) {
      client_id
      user_role_id
      username
      pd_team_edit
      first_name
      last_name
      account_balance
      transactional_account_balance
      token
      team_id
      admin_id
      partner_id
      __typename
    }
  }
`;
export const getLineItemByID = /* GraphQL */ `
  query GetLineItemByID($line_item_id: Int!) {
    getLineItemByID(line_item_id: $line_item_id) {
      line_item_id
      manufacturer_id
      sku
      line_item_code
      user_id
      team_id
      doc_type_id
      scope_type_id
      name
      description
      cost
      quantity
      adjusted_quantity
      unit
      convert_to
      ratio_from
      ratio_to
      convert_round
      algorithm
      labor_cost
      material_cost
      profit
      overhead
      waste
      notes
      sub_line_item
      product
      manufacturer
      apply_op
      status
      sort_order
      doc_line_item_id
      doc_id
      doc_structure_id
      doc_category_id
      remove_prices
      __typename
    }
  }
`;
export const getLineItemByIDs = /* GraphQL */ `
  query GetLineItemByIDs($ids: String) {
    getLineItemByIDs(ids: $ids)
  }
`;
export const getDocumentByUserID = /* GraphQL */ `
  query GetDocumentByUserID($user_id: Int!, $team_id: Int!) {
    getDocumentByUserID(user_id: $user_id, team_id: $team_id) {
      doc_id
      ref_id
      user_id
      team_id
      doc_type_id
      template_id
      name
      location
      header
      options
      footer
      claim_number
      structure_count
      contact_id
      owner_contact_id
      date_created
      date_updated
      label
      invoice_label
      notes
      pdf
      __typename
    }
  }
`;
export const getDocumentByUserIDx = /* GraphQL */ `
  query GetDocumentByUserIDx(
    $user_id: Int
    $team_id: Int
    $limit: Int
    $offset: Int
    $name_location: String
    $doc_type: Int
    $ref_claim_num: String
    $created_date_start: String
    $created_date_end: String
    $updated_date_start: String
    $updated_date_end: String
  ) {
    getDocumentByUserIDx(
      user_id: $user_id
      team_id: $team_id
      limit: $limit
      offset: $offset
      name_location: $name_location
      doc_type: $doc_type
      ref_claim_num: $ref_claim_num
      created_date_start: $created_date_start
      created_date_end: $created_date_end
      updated_date_start: $updated_date_start
      updated_date_end: $updated_date_end
    )
  }
`;
export const getDocumentByTeamID = /* GraphQL */ `
  query GetDocumentByTeamID(
    $team_id: Int
    $limit: Int
    $offset: Int
    $name_location: String
    $doc_type: Int
    $ref_claim_num: String
    $created_date_start: String
    $created_date_end: String
    $updated_date_start: String
    $updated_date_end: String
  ) {
    getDocumentByTeamID(
      team_id: $team_id
      limit: $limit
      offset: $offset
      name_location: $name_location
      doc_type: $doc_type
      ref_claim_num: $ref_claim_num
      created_date_start: $created_date_start
      created_date_end: $created_date_end
      updated_date_start: $updated_date_start
      updated_date_end: $updated_date_end
    )
  }
`;
export const getDocumentsListingElasticSearch = /* GraphQL */ `
  query GetDocumentsListingElasticSearch(
    $user_id: Int
    $team_id: Int
    $limit: Int
    $offset: Int
    $name_location: String
    $doc_type_id: Int
    $ref_claim_num: String
    $created_date_start: String
    $created_date_end: String
    $updated_date_start: String
    $updated_date_end: String
  ) {
    getDocumentsListingElasticSearch(
      user_id: $user_id
      team_id: $team_id
      limit: $limit
      offset: $offset
      name_location: $name_location
      doc_type_id: $doc_type_id
      ref_claim_num: $ref_claim_num
      created_date_start: $created_date_start
      created_date_end: $created_date_end
      updated_date_start: $updated_date_start
      updated_date_end: $updated_date_end
    )
  }
`;
export const getTemplateDocument = /* GraphQL */ `
  query GetTemplateDocument($template_id: Int!) {
    getTemplateDocument(template_id: $template_id) {
      doc_id
      ref_id
      user_id
      team_id
      doc_type_id
      template_id
      name
      location
      header
      options
      footer
      claim_number
      structure_count
      contact_id
      owner_contact_id
      date_created
      date_updated
      label
      invoice_label
      notes
      pdf
      __typename
    }
  }
`;
export const getDocumentByID = /* GraphQL */ `
  query GetDocumentByID($doc_id: Int!) {
    getDocumentByID(doc_id: $doc_id) {
      doc_id
      ref_id
      user_id
      team_id
      doc_type_id
      template_id
      name
      location
      header
      options
      footer
      claim_number
      structure_count
      contact_id
      owner_contact_id
      date_created
      date_updated
      label
      invoice_label
      notes
      pdf
      __typename
    }
  }
`;
export const getContactByClientID = /* GraphQL */ `
  query GetContactByClientID($client_id: Int!, $type: String!, $limit: Int) {
    getContactByClientID(client_id: $client_id, type: $type, limit: $limit)
  }
`;
export const getCompletedScopesByClientID = /* GraphQL */ `
  query GetCompletedScopesByClientID(
    $client_id: Int!
    $order_id: Int
    $order_ids: String
    $search: String
    $filter_ids: String
    $limit: Int
  ) {
    getCompletedScopesByClientID(
      client_id: $client_id
      order_id: $order_id
      order_ids: $order_ids
      search: $search
      filter_ids: $filter_ids
      limit: $limit
    )
  }
`;
export const getMarkups = /* GraphQL */ `
  query GetMarkups($user_id: Int!, $team_id: Int!) {
    getMarkups(user_id: $user_id, team_id: $team_id)
  }
`;
export const uploadTemplate = /* GraphQL */ `
  query UploadTemplate(
    $user_id: Int!
    $team_id: Int!
    $email: String!
    $data: String!
    $date: String
  ) {
    uploadTemplate(
      user_id: $user_id
      team_id: $team_id
      email: $email
      data: $data
      date: $date
    )
  }
`;
export const createTemplate = /* GraphQL */ `
  query CreateTemplate(
    $doc_type_id: Int!
    $user_id: Int!
    $team_id: Int!
    $name: String!
    $default_template_id: Int
    $default_category_ids: String
    $email: String!
  ) {
    createTemplate(
      doc_type_id: $doc_type_id
      user_id: $user_id
      team_id: $team_id
      name: $name
      default_template_id: $default_template_id
      default_category_ids: $default_category_ids
      email: $email
    )
  }
`;
export const updateProjectInfo = /* GraphQL */ `
  query UpdateProjectInfo(
    $doc_id: Int
    $name: String
    $location: String
    $ref_id: String
    $claim_number: String
    $structure_count: Int
  ) {
    updateProjectInfo(
      doc_id: $doc_id
      name: $name
      location: $location
      ref_id: $ref_id
      claim_number: $claim_number
      structure_count: $structure_count
    )
  }
`;
export const updateProjectOwner = /* GraphQL */ `
  query UpdateProjectOwner($doc_id: Int, $owner_contact_id: Int) {
    updateProjectOwner(doc_id: $doc_id, owner_contact_id: $owner_contact_id)
  }
`;
export const updateTemplateDescription = /* GraphQL */ `
  query UpdateTemplateDescription($template_id: Int, $description: String) {
    updateTemplateDescription(
      template_id: $template_id
      description: $description
    )
  }
`;
export const updateContactInfo = /* GraphQL */ `
  query UpdateContactInfo(
    $contact_id: Int
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
  ) {
    updateContactInfo(
      contact_id: $contact_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
    )
  }
`;
export const updateDocumentOptions = /* GraphQL */ `
  query UpdateDocumentOptions($doc_id: Int, $options: String) {
    updateDocumentOptions(doc_id: $doc_id, options: $options)
  }
`;
export const updateDocumentLabel = /* GraphQL */ `
  query UpdateDocumentLabel($doc_id: Int, $label: String) {
    updateDocumentLabel(doc_id: $doc_id, label: $label)
  }
`;
export const updateInvoiceLabel = /* GraphQL */ `
  query UpdateInvoiceLabel($doc_id: Int, $label: String) {
    updateInvoiceLabel(doc_id: $doc_id, label: $label)
  }
`;
export const deleteFile = /* GraphQL */ `
  query DeleteFile($fileId: Int!, $customerId: Int!, $fileName: String) {
    deleteFile(fileId: $fileId, customerId: $customerId, fileName: $fileName)
  }
`;
export const getFiles = /* GraphQL */ `
  query GetFiles($customerIds: String!) {
    getFiles(customerIds: $customerIds)
  }
`;
export const uploadFile = /* GraphQL */ `
  query UploadFile($customerId: Int!, $folderId: Int!, $fileList: String!) {
    uploadFile(
      customerId: $customerId
      folderId: $folderId
      fileList: $fileList
    )
  }
`;
export const createOwnerContact = /* GraphQL */ `
  query CreateOwnerContact(
    $customer_id: Int
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
  ) {
    createOwnerContact(
      customer_id: $customer_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
    )
  }
`;
export const createDocumentContact = /* GraphQL */ `
  query CreateDocumentContact(
    $customer_id: Int
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
  ) {
    createDocumentContact(
      customer_id: $customer_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
    )
  }
`;
export const createDocLineItem = /* GraphQL */ `
  query CreateDocLineItem(
    $doc_id: Int
    $doc_structure_id: Int
    $doc_category_id: Int
    $line_item_id: Int
    $manufacturer_id: Int
    $sku: String
    $scope_type_id: Int
    $line_item_code: String
    $name: String
    $description: String
    $cost: Float
    $quantity: Float
    $adjusted_quantity: Float
    $unit: String
    $convert_to: String
    $ratio_from: Float
    $ratio_to: Float
    $convert_round: Int
    $algorithm: String
    $labor_cost: Float
    $material_cost: Float
    $profit: Float
    $overhead: Float
    $waste: Float
    $notes: String
    $sub_line_item: String
    $manufacturer: String
    $apply_op: Int
    $product: String
    $color: String
    $remove_prices: Int
    $sort_order: Int
  ) {
    createDocLineItem(
      doc_id: $doc_id
      doc_structure_id: $doc_structure_id
      doc_category_id: $doc_category_id
      line_item_id: $line_item_id
      manufacturer_id: $manufacturer_id
      sku: $sku
      scope_type_id: $scope_type_id
      line_item_code: $line_item_code
      name: $name
      description: $description
      cost: $cost
      quantity: $quantity
      adjusted_quantity: $adjusted_quantity
      unit: $unit
      convert_to: $convert_to
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      convert_round: $convert_round
      algorithm: $algorithm
      labor_cost: $labor_cost
      material_cost: $material_cost
      profit: $profit
      overhead: $overhead
      waste: $waste
      notes: $notes
      sub_line_item: $sub_line_item
      manufacturer: $manufacturer
      apply_op: $apply_op
      product: $product
      color: $color
      remove_prices: $remove_prices
      sort_order: $sort_order
    )
  }
`;
export const createLineItem = /* GraphQL */ `
  query CreateLineItem(
    $manufacturer_id: Int
    $sku: String
    $scope_type_id: Int
    $line_item_code: String
    $name: String
    $description: String
    $cost: Float
    $quantity: Float
    $adjusted_quantity: Float
    $unit: String
    $convert_to: String
    $ratio_from: Float
    $ratio_to: Float
    $convert_round: Int
    $algorithm: String
    $labor_cost: Float
    $material_cost: Float
    $profit: Float
    $overhead: Float
    $waste: Float
    $notes: String
    $sub_line_item: String
    $manufacturer: String
    $apply_op: Int
    $product: String
    $status: Int
    $doc_type_id: Int
    $user_id: Int
    $team_id: Int
  ) {
    createLineItem(
      manufacturer_id: $manufacturer_id
      sku: $sku
      scope_type_id: $scope_type_id
      line_item_code: $line_item_code
      name: $name
      description: $description
      cost: $cost
      quantity: $quantity
      adjusted_quantity: $adjusted_quantity
      unit: $unit
      convert_to: $convert_to
      ratio_from: $ratio_from
      ratio_to: $ratio_to
      convert_round: $convert_round
      algorithm: $algorithm
      labor_cost: $labor_cost
      material_cost: $material_cost
      profit: $profit
      overhead: $overhead
      waste: $waste
      notes: $notes
      sub_line_item: $sub_line_item
      manufacturer: $manufacturer
      apply_op: $apply_op
      product: $product
      status: $status
      doc_type_id: $doc_type_id
      user_id: $user_id
      team_id: $team_id
    )
  }
`;
export const createStructure = /* GraphQL */ `
  query CreateStructure(
    $doc_id: Int
    $structure_id: Int
    $name: String
    $sort_order: Int
  ) {
    createStructure(
      doc_id: $doc_id
      structure_id: $structure_id
      name: $name
      sort_order: $sort_order
    )
  }
`;
